import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/mdx.js";
import TeamMembers from "src/components/team-members";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageHeading = makeShortcode("PageHeading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeading title={props.pageContext.frontmatter.title} image="working" crumbs={["TechSlice", "About", props.pageContext.frontmatter.title]} mdxType="PageHeading"></PageHeading>
    <div className="container">
  <h2 style={{
        color: "var(--primary)"
      }}>Meet Our Team</h2>
  <TeamMembers mdxType="TeamMembers"></TeamMembers>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      